import Helmet from "react-helmet"
import React from "react"
import ThankYouPage from "src/components/tyclearbar"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"



export default function TYclearbar() {
  return(
    <>
    <Helmet>
      <style type="type/css">{`


        `}
        </style>
        <title>Thank You for Downloading FromPDFtoDOC</title>
      </Helmet>
    <section>
      <ThankYouPage data={Data} siteData={SiteData}></ThankYouPage>
    </section>
    </>
  )
}
