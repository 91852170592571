import React from "react"
import Styles from "./css/typcb.module.scss"
import {isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import Footerlinks from "@tightrope/footerlinks/footer"


class TyCB extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      pageData: {},
      data: {},
      siteData: {}
    }
  }
  componentDidMount(){
    let data = this.props.data;
    let siteData = this.props.siteData;
    this.setState({data: data, siteData: siteData});
    if(isChrome()){
      const browserData = Object.assign(data, data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(data, data.firefox);
      this.setState({data: browserData});
    }
  }

  render() {
    return (
<div className={Styles.typContainer}>
<img src="/assets/clear-logo.png" className={Styles.logo} />
<section>
<img src={this.state.siteData.logo} className={Styles.typLogo} />
	<h1>Thank You for Installing {this.state.data.productName} by Clear.</h1>
	<ol>
		<li>Look for the {this.state.data.productName} Icon <img className={Styles.carrot} src="/assets/icon.png"/> in the app bar at the top of your screen.</li>
		<li>Click on it to select conversion type and start converting for free!</li>
	</ol>
</section>
    <Footerlinks></Footerlinks>
    </div>
    )
  }
}

export default TyCB
